import { Routes } from '@angular/router';
import {SimpleBookingComponent} from "./simple-booking/simple-booking.component";
import {EventListComponent} from "./event-list/event-list.component";
import {ReceiptComponent} from "./receipt/receipt.component";
import {EventGroupComponent} from "./event-group/event-group.component";

export const routes: Routes = [
  { path: 'simple-booking/:eventName', component:SimpleBookingComponent},
  { path: 'events', component:EventListComponent},
  { path: 'group-event/:groupName', component:EventGroupComponent},
  { path: 'receipt/:id', component:ReceiptComponent},
  { path: '**', redirectTo: 'events' }
];
