import { CurrencyPipe, JsonPipe, NgForOf, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Notify } from "notiflix";
import { MessageService } from "primeng/api";
import { ButtonGroupModule } from "primeng/buttongroup";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { PanelModule } from "primeng/panel";
import { SelectButtonModule } from "primeng/selectbutton";
import { StepperModule } from "primeng/stepper";
import { StepsModule } from "primeng/steps";
import { ReservationSummaryComponent } from "../reservation-summary/reservation-summary.component";
import TotalsUtils from "../utils/TotalsUtils";
import {environment} from "../../environments/environment";

@Component({
  selector: 'pdg-simple-booking',
  standalone: true,
  providers: [CurrencyPipe, MessageService, DialogService],
  imports: [
    MessagesModule,
    MessageModule,
    FormsModule,
    NgSwitch,
    NgForOf,
    NgSwitchCase,
    InputNumberModule,
    CardModule,
    InputTextModule,
    CalendarModule,
    NgIf,
    SelectButtonModule,
    CurrencyPipe,
    NgOptimizedImage,
    InputTextareaModule,
    ReservationSummaryComponent,
    CheckboxModule,
    JsonPipe,
    StepperModule,
    StepsModule,
    ButtonGroupModule,
    PanelModule,
    InputGroupModule,
    InputGroupAddonModule,
  ],
  templateUrl: './simple-booking.component.html',
  styleUrl: './simple-booking.component.scss'
})
export class SimpleBookingComponent implements OnInit {
  // @ViewChild(ReservationSummaryComponent) summaryModal!: ReservationSummaryComponent;
  ref: DynamicDialogRef | undefined;
  conf: any = {} as any;
  stepperLoaded = false;
  testIndex: any = {};
  activeStep = 0;
  sessi: any[] = [{label: 'MASCHIO', value: 'MASCHIO'}, {label: 'FEMMINA', value: 'FEMMINA'}];

  constructor(private http: HttpClient, private route: ActivatedRoute, private currencyPipe: CurrencyPipe,public dialogService: DialogService) {

  }

  show() {

}

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('eventName')
    this.http.get(environment.eventData + id).subscribe(res => {
      this.conf = res;
      this.conf.confReservationElements.sort((n1: any, n2: any) => n1.position - n2.position)
      let i = 0;
      this.conf.confReservationElements.forEach((el: any) => {

        if (el.confReservationInputType.id == 5) {
          el.value = 0;
        }
        el.info = []
        if (el.confReservationInputType.id == 9) {
          el.info.push({})
          this.testIndex[i++] = 0;
        }else{
          this.testIndex[i++] = -1;
        }

        el.detailsInfoList.sort((n1: any, n2: any) => n1.position - n2.position)
      })
      this.stepperLoaded = true;
    });
  }

  getArrayChild(value: any) {
    if (value) {
      let x = [];
      for (let i = 0; i < value; i++) {
        x.push(i)
      }
      return x;
    } else return []
  }


  openSummary() {
    // this.summaryModal.show();
  }

  elaborateValidation(validations: any, validationType: string, inputType: string) {
    switch (inputType) {
      case 'TEXT':
      case 'REGEX':
      case'NUMBER': {
        let val = validations.find((v: any) => v.confReservationElementValidationType.name == validationType);
        if (val == undefined) {
          return undefined;
        } else {
          return val.value;
        }
      }
    }
    return undefined;
  }

  onInputChange(value: any, el: any, elIndex: any, prevCallBack: any, mainIndex: any) {

    if (el.info == undefined) {
      el.info = [];
    }
    if (el.info.length > value) {
      for (let i = 0; i <= el.info.length - value; i++) {
        if (this.testIndex[elIndex] >= el.info.length - 1) {
          this.prevIndex(null, elIndex, prevCallBack, mainIndex)
        }
        el.info.pop();
      }

      if (el.info.length == 0) {
        this.testIndex[elIndex] = -1;
      }
    }
    if (value > el.info.length) {
      for (let i = 0; i <= value - el.info.length; i++) {
        el.info.push(this.getInfo(el))
      }
      if (el.info.length == 1) {
        this.testIndex[elIndex] = 0;
      }
    }


  }

  getInfo(el: any) {
    let res: any = {};
    el.detailsInfoList.filter((x: any) => x.confReservationInputType.id == 8).forEach((x: any) => {
      x.confReservationElementOptions.forEach((option: any) => {
        res[x.id + "_" + option.id] = true;
      })
    })
    return res;
  }


  protected readonly TotalsUtils = TotalsUtils;

  getSteps(info: any) {
    let steps: any = [];
    info.forEach((x: any) => steps.push({label: ''}))
    return steps;
  }

  nextIndex(steps: any, elIndex: any, callback: any, mainIndex: any) {
    if (this.validateElement(this.activeStep, elIndex).length > 0) return;
    if (this.testIndex[elIndex] + 1 >= steps.length) {
      if (mainIndex + 1 == this.conf.confReservationElements.length) {
        // this.summaryModal.show();
        this.ref = this.dialogService.open(ReservationSummaryComponent, {
          data: this.conf,
          width: '100vw',
          modal:true,
          header: 'Riepilogo',
          baseZIndex: 3998,
          breakpoints: {
            '600px': '100vw'
          },
        });
      } else {
        callback.emit()
      }
      return;
    }
    this.testIndex[elIndex]++;
  }

  prevIndex(steps: any, elIndex: any, callback: any, mainIndex: any) {
    if (this.testIndex[elIndex] <= 0) {
      callback.emit()
      return;
    }
    this.testIndex[elIndex]--;
  }


  getDecreaseValue(el: any, elIndex: any, prevCallback: any, mainIndex: any, minValue: any) {
    let newValue = el.value;
    if (newValue - 1 < minValue) return el.value;
    if (isNaN(newValue) || newValue == 0) {
      newValue = 0
    } else {
      newValue--;
    }
    this.onInputChange(newValue, el, elIndex, prevCallback, mainIndex)
    return newValue
  }

  getIncreaseValue(el: any, elIndex: any, mainIndex: any, maxValue: any) {
    let newValue = el.value;
    if (newValue + 1 > maxValue) return el.value;
    if (isNaN(newValue)) {
      newValue = 1
    } else {
      newValue++;
    }
    this.onInputChange(newValue, el, elIndex, null, mainIndex)
    return newValue;
  }

  prevButton() {
    // @ts-ignore
    document.getElementById('prevButton_' + this.activeStep).click();
  }

  nextButton() {
    // @ts-ignore
    document.getElementById('nextButton_' + this.activeStep).click();
  }

  getCheckboxLabel(item: any) {
    let price:any = "";
    if(item.showPrice){
      price = this.currencyPipe.transform(item.priceCent / 100, 'EUR', "symbol");
    }
    return item.label + ' ' + price;
  }

  validateElement(elementId: any, index: any) {
    let el: any = this.conf.confReservationElements[elementId];
    let res = [];
    switch (el.confReservationInputType.id) {
      case 1:
        res.push(...this.validateText(el.value, el.confReservationElementValidationList, el.label));
        break;
      case 5:
        res.push(...this.validateNumber(el.value, el.confReservationElementValidationList, el.label));
        break;
    }
    if (el.info.length > 0) {
      el.detailsInfoList.forEach((info: any) => {
        switch (info.confReservationInputType.id) {
          case 1:
            res.push(...this.validateText(el.info[this.testIndex[index]][info.id], info.confReservationElementValidationList, info.label));
            break;
          case 5:
            res.push(...this.validateNumber(el.info[this.testIndex[index]][info.id], info.confReservationElementValidationList, info.label));
            break;
        }
      });
    }
    res.forEach(el => Notify.warning(el));
    return res;
  }

  validateText(value: any, validations: any, label: any) {
    let res: any[] = [];
    validations.forEach((validation: any) => {
      switch (validation.confReservationElementValidationType.id) {
        case 1:
          if (value) {
            if (value.length < validation.value) {
              res.push(label + " deve avere almeno " + validation.value + " caratteri!");
            }
          }
          break;
        case 2:
          if (value) {
            if (value.length > validation.value) {
              res.push(label + " deve avere al massimo " + validation.value + " caratteri!");
            }
          }
          break;
        case 3:
          if (!value) res.push(label + " obbligatorio!")
          if (value && value.trim() == "") res.push(label + " obbligatorio!")
          break;
        case 4:
          let test= false;
          console.log(validation.value)
          if(value && value.trim()!=""){
            console.log(value)
            test = new RegExp(validation.value).test(value)
          }
          if(!test){
            res.push(label + " non valido!")
          }
          break;
      }
    })
    return res;
  }

  validateNumber(value: any, validations: any, label: any) {
    let res: any[] = [];
    validations.forEach((validation: any) => {
      switch (validation.confReservationElementValidationType.id) {
        case 1:
          if (value < validation.value) {
            res.push("Devi selezionare almeno un elemento");
          }
          break;
        case 3:
          if (value == null) res.push("CAMPO NECESSARIO")
          break;
      }
    })
    return res;
  }
}
