<div>
  <div style="margin:auto">
    <table cellspacing="0" width="100%">
      <tr style="background-color:black; color:white">
        <td>Descrizione</td>
        <td>Quantità</td>
        <td>Prezzo unitario</td>
        <td>Totale</td>
      </tr>
      <ng-container *ngFor="let el of dialogService.data.confReservationElements">
        <ng-container *ngIf="(el.value!=0&&el.value!=''&&el.value!=null)||el.confReservationInputType.id==9">
          <tr style="background-color:darkgrey">
            <td>{{el.label}}</td>
            <td>{{el.priceType.id != 3 ? (el.value) : ""}}</td>
            <td>{{el.priceType.id != 3 && el.priceCent > 0 ? (el.priceCent / 100|currency:'EUR':"symbol") : ""}}</td>
            <td>{{el.priceType.id != 3 ? (TotalsUtils.getPartial(el)|currency:'EUR':"symbol") : ""}}</td>
          </tr>
          <tr>
            <td colspan="4" *ngIf="el.confReservationInputType.id!=5&&el.confReservationInputType.id!=9">
              {{el.value}}
            </td>
            <td colspan="4" *ngIf="el.confReservationInputType.id==5||el.confReservationInputType.id==9">
              <ul>
                <li *ngFor="let info of el.info">
                  <div [innerHTML]="getInfoRows(info, el)"></div>
                </li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <tr>
        <td></td>
        <td [colSpan]="1"><b>TOTALE</b></td>
        <td [colSpan]="1" style="text-align: center">
          <b>{{(TotalsUtils.getTotale(dialogService.data))|currency:'EUR':"symbol"}}</b> <br>ACCONTO:
          <b>{{calculatePaymentAdvance(dialogService.data.paymentAdvanceType)|currency:'EUR':"symbol"}}</b>
        </td>
        <td></td>
      </tr>
    </table>
    <div style="margin: auto;width: 300px">
      <div class="p-field p-fluid">
        <label for="couponCode">Coupon code</label>
        <input pInputText id="couponCode" class="p-inputtext-sm p-invalid" aria-describedby="couponCode-help"
               type="text" [(ngModel)]="dialogService.data.couponCode"/>
      </div>
      <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    </div>
  </div>
</div>
