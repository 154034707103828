import {CurrencyPipe, DatePipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {IPayPalConfig, NgxPayPalModule} from "ngx-paypal";
import {Loading, Report} from "notiflix";
import {DialogModule} from "primeng/dialog";
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import TotalsUtils from "../utils/TotalsUtils";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {environment} from "../../environments/environment";

@Component({
  selector: 'pdg-reservation-summary',
  standalone: true,
  imports: [
    DialogModule,
    JsonPipe,
    NgForOf,
    CurrencyPipe,
    NgxPayPalModule,
    NgIf,
    InputTextModule,
    PaginatorModule
  ],
  templateUrl: './reservation-summary.component.html',
  styleUrls: ['./reservation-summary.component.scss']
})
export class ReservationSummaryComponent implements OnInit {

  visible = false;
  public payPalConfig ?: IPayPalConfig;

  constructor(public dialogService: DynamicDialogConfig) {
  }


  ngOnInit(): void {
    this.visible = true;
    if (this.payPalConfig == null) {
      // setTimeout(() => {
      this.initConfig();
      // }, 1000);
    }
  }

  private initConfig(): void {

    this.payPalConfig = {
      clientId: environment.paypalKey,
      // for creating orders (transactions) on server see
      currency: 'EUR',
      createOrderOnServer: (data: any) => fetch(environment.createOrder, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.dialogService.data)
      })
        .then((res) => res.json())
        .then((order) => {
          console.log(order.orderId)
            if(order.status == 500){
              Report.warning('Qualcosa è andato storto', 'Per favore riprova più tardi!', 'Okay', () => window.location.reload(), {zindex: 5500});
            } else if (order.orderId == 'FREE!!!') {
              Report.success('Prenotazione avvenuta con sucesso!', 'Grazie! Riceverai a breve una mail con tutti i dettagli della prenotazione!', 'Okay', () => window.location.reload(), {zindex: 5500});
              return null;
            }
          return order.orderId
          }
        ),
      onApprove: (data: any, actions: any) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details: any) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data: any) => {
        Loading.standard({zindex: 5500})
        fetch(environment.orderCapture + data.id, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then((res: any) => {
            Loading.remove();
            Report.success('Prenotazione avvenuta con sucesso', 'Grazie! Riceverai a breve una mail con tutti i dettagli della prenotazione!', 'Okay', () => window.location.reload(), {zindex: 5500});
          }).catch(function (error: any) {
          Report.warning('Qualcosa è andato storto', 'Per favore riprova più tardi!', 'Okay', () => window.location.reload(), {zindex: 5500});
        })
      },
      onCancel: (data: any, actions: any) => {
        console.log('OnCancel', data, actions);
        // this.showCancel = true;

      },
      onError: (err: any) => {
        console.log('OnError', err);
        // this.showError = true;
      },
      onClick: (data: any, actions: any) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      },
    };
  }

  getInfoRows(row: any, el: any) {
    let printStyleConf = el.detailAggregationConf;
    let subItemMap: any = {};
    el.detailsInfoList.filter((subItem: any) => subItem.confReservationInputType.id == 8).forEach((subItem: any) => {
      let subItemId = subItem.id;
      subItem.confReservationElementOptions.forEach((option: any) => subItemMap[subItemId + "_" + option.id] = option)
    })
    if (printStyleConf != null) {
      let aggregationItem = printStyleConf.split("###")
      let map: any = {};
      let endString = "<ul>";
      Object.entries(row)
        .forEach(([key, value]) => {
          let elementName: any = key
          if (map == undefined) {
            map = {};
            map[elementName] = value
          } else {
            map[elementName] = value
          }
          if (elementName.indexOf("_") != -1 && (row[elementName] == true || el.detailsInfoList.find((subItem: any) => subItem.id == elementName.split("_")[0]).confReservationInputType.id != 8)) {
            let price = subItemMap[elementName].showPrice ? subItemMap[elementName].priceCent / 100 + " €" : "";
            endString = endString + " <li>" + subItemMap[elementName].label + " <b>" + price + "</b></li> ";
          }

        });
      endString = endString + "</ul>"
      let string = "";
      aggregationItem.forEach((v: any) => {
        let pName = v.split("___")[0]
        let pType = v.split("___")[1]
        if (map[pName]) {
          if (pType == "DATE") {
            const datepipe: DatePipe = new DatePipe('en-US')
            let formattedDate = datepipe.transform(map[pName], 'dd-MM-YYYY')
            string += formattedDate + " ";
          } else {
            string += map[pName] + " ";
          }
        }
      })
      return "<div class='col-12'>" + string + "</div>" + " <br>" + endString;
    }
    return "";

  }

  protected readonly TotalsUtils = TotalsUtils;

  calculatePaymentAdvance(paymentAdvanceType: any) {
    switch (paymentAdvanceType) {
      case "FIXED":
        return this.dialogService.data.paymentAdvanceAmount / 100;
      case "PERCENTAGE":
        return TotalsUtils.getTotale(this.dialogService.data) * this.dialogService.data.paymentAdvanceAmountbreak / 100;
      case "CUSTOM": {
        let configurations = this.dialogService.data.paymentAdvanceParams.split("###");
        let res = 0;
        configurations.forEach((configuration: any) => {
          let el = configuration.split("___")[0];
          let amountConf = configuration.split("___")[1];
          res = res + this.dialogService.data.confReservationElements.filter((element: any) => element.id == el)[0].value * +amountConf
        })
        return res / 100;
      }
      default:
        return 0;
    }
  }
}
