import {Component, Input, OnInit} from '@angular/core';
import {CardModule} from "primeng/card";
import {SharedModule} from "primeng/api";
import {HttpClient} from "@angular/common/http";
import {NgForOf, NgIf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'pdg-event-list',
  standalone: true,
  imports: [
    CardModule,
    SharedModule,
    NgForOf,
    ButtonModule,
    RouterLink,
    NgIf
  ],
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.scss'
})
export class EventListComponent implements OnInit{
  @Input() groupName: string|null = null;
  events: any = [];

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
    let url = environment.event;
    if(this.groupName!=null){
      url = environment.eventGroup + this.groupName
    }
    this.http.get(url).subscribe(res => {
      this.events = res;
    });
  }


}
