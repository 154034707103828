import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {CurrencyPipe, formatDate, JsonPipe, NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputGroupModule} from "primeng/inputgroup";
import {ButtonModule} from "primeng/button";
import {Report} from "notiflix";
import {environment} from "../../environments/environment";

@Component({
  selector: 'pdg-receipt',
  standalone: true,
  imports: [
    JsonPipe,
    TableModule,
    CurrencyPipe,
    NgIf,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    InputGroupModule,
    ButtonModule
  ],
  templateUrl: './receipt.component.html',
  styleUrl: './receipt.component.scss'
})
export class ReceiptComponent {
  public res: any;
  public verificationCode:any;
  constructor(private route: ActivatedRoute, private http:HttpClient) {}

  ngOnInit() {

  }

  loadOrder(){
    let id = this.route.snapshot.paramMap.get('id');
    this.http.get(environment.receipt + id+"/verification/"+this.verificationCode).subscribe(res=>{
      this.res=res;
      if(res==null) {
        Report.failure('Peccato','Sembra che l\'email o il numero di telefono che hai inserito non siano corretti!' ,'Okay',);
      }else{
        this.res.orderElements = this.res.orderElements.sort((n1: any, n2: any) => n1.confReservationElement.position > n2.confReservationElement.position)
      }
    })
  }

  getInfoRows(el: any) {
    let printStyleConf = el.confReservationElement.detailAggregationConf;

    let grouped = el.detailsInfoList.reduce(function (r:any, a:any) {
      r[a.parentGroup] = r[a.parentGroup] || [];
      r[a.parentGroup].push(a);
      return r;
    }, Object.create(null));
    let resString = "";
    Object.keys(grouped).forEach((v:any)=>{
      let endString = "<ul>";
      let row = grouped[v];
      row.filter((r:any)=>r.option).forEach((r:any)=>endString=endString+"<li>" + r.confReservationElementOptions.label+"<b> "+ r.confReservationElementOptions.priceCent/100 + "€</b></li>")
      endString = endString + "</ul>"
      let string = "";
      if (printStyleConf != null) {
        let aggregationItem = printStyleConf.split("###")
        let map: any = {};
        aggregationItem.forEach((v: any) => {
          let pName = v.split("___")[0]
          let pType = v.split("___")[1]
          let el = row.find((r:any)=>r.confReservationElement!=null && r.confReservationElement.id==pName);
          if (el) {
            if(pType=="DATE"){
              string += formatDate(new Date(el.value),'dd-MM-yyyy','en-US') + " ";
            }else {
              string += el.value + " ";
            }
          }
        })
      }
      resString = resString + string + endString
    })


    return resString;
  }

  totalSubItem(element: any) {
    let total = 0;
    if(element.confReservationElement!=null) {
      total += element.value * element.confReservationElement.priceCent;
    }
    element.detailsInfoList.filter((r:any)=>r.option).forEach((r:any)=>total+=r.confReservationElementOptions.priceCent)
    return total/100;
  }
}
