import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EventListComponent} from "../event-list/event-list.component";
import {BehaviorSubject, timeout} from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: 'pdg-event-group',
  standalone: true,
  imports: [
    EventListComponent,
    NgIf
  ],
  templateUrl: './event-group.component.html',
  styleUrl: './event-group.component.scss'
})
export class EventGroupComponent implements OnInit{

  groupName:string|null = null;
  constructor(private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    setTimeout( () => {
      this.groupName = this.route.snapshot.paramMap.get('groupName');
      console.log(this.groupName)
    },500)

  }

}
