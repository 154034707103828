<div class="main-container">
  <ng-container  *ngFor="let event of events">
  <p-card header="{{event.title}}">
    <ng-template pTemplate="header">
      <img alt="Card" [src]="event.img"/>
    </ng-template>
    <p>
      {{ event.description }}
    </p>
    <div [innerHTML]="event.descriptionHtml"></div>
    <p-button label="Prenota" [routerLink]="['/simple-booking/'+event.name]"></p-button>
  </p-card>
  </ng-container>
</div>
