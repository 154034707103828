<div class="main-container">
  <div  *ngIf="res==null">
    <div class="p-field p-fluid">
      <label for="asdwqeq">Inserisci email o numero di telefono per recuparare la prenotazione</label>
      <p-inputGroup>
      <input pInputText id="asdwqeq" class="p-inputtext-sm p-invalid" aria-describedby="asdwqeq-help"
             type="text" [(ngModel)]="verificationCode"/>
        <button type="button" pButton icon="pi pi-play" class="p-button-info" (click)="loadOrder()"></button>
      </p-inputGroup>
    </div>
  </div>
  <p-table [value]="res.orderElements" *ngIf="res!=null">
    <ng-template pTemplate="header">
      <tr>
        <th>LABEL</th>
        <th>VALUE</th>
        <th>PRICE x UNIT</th>
        <th>TOTAL PRICE</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr>
        <td>{{element.confReservationElement.label}}</td>
        <td>{{element.value}}</td>
        <td>{{element.confReservationElement.priceType.id==3?"": element.confReservationElement.priceCent/100|currency:'EUR'}}</td>
        <td>{{element.confReservationElement.priceType.id==3?"": totalSubItem(element)|currency:'EUR'}}</td>
      </tr>
      <tr>
        <td [colSpan]="4">
          <div [innerHTML]="getInfoRows(element)"></div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
