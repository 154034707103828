export default class TotalsUtils {
  static getTotale(conf:any) {
    if(conf.confReservationElements == undefined){
      return 0;
    }
    return conf.confReservationElements.reduce((acc:any, currentValue:any) => {
      let part = TotalsUtils.getPartial(currentValue);
      if(!isNaN(part) && part != undefined){
       return acc+TotalsUtils.getPartial(currentValue)
      }else{
        return acc;
      }
    }, 0);
  }

  static getPartial(el: any) {
    let res =el.value * el.priceCent;
    el.info.forEach((info: any) => {
      Object.entries(info).forEach(([k, v]) => {
        if (k.indexOf("_")!=-1) {
          let confEl = el.detailsInfoList.find((el: any) => el.id == k.split("_")[0]);
          let confOption = confEl.confReservationElementOptions.find((el: any) => el.id == k.split("_")[1]);
          if (confEl.priceType!=null && confEl.priceType.id == 2 && v==true) {
            res+=confOption.priceCent;
          }
        }
      })
    })
    return res/100;
  }
}
