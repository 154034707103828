<div class="main-container">
  <div class="grid header-info">
    <div class="col-6 p-3">{{conf.name}}</div>
    <div class="col-6 p-3" style="text-align: right;"><b>{{TotalsUtils.getTotale(conf)|currency:'EUR':"symbol"}}</b></div>
  </div>
  <p-stepper orientation="vertical" *ngIf="stepperLoaded" [linear]="true" [(activeStep)]="activeStep">
    <p-stepperPanel header="{{el.label}} {{TotalsUtils.getPartial(el)|currency:'EUR':'symbol'}}"
                    *ngFor="let el of conf.confReservationElements;index as elIndex">
      <ng-template pTemplate="content"
                   let-nextCallback="nextCallback" let-prevCallback="prevCallback" let-index="index">
        <!--  price per unit:{{el.priceCent}}<br>-->
        <!--  TOTALE {{el.label}}:{{el.priceCent * el.value}}<br>-->
        <div class="card flex flex-wrap gap-3 p-fluid">
          <ng-container [ngSwitch]="el.confReservationInputType.id">

            <div *ngSwitchCase="5">
              <div class="flex-auto">
                <p-inputGroup>

                  <button type="button" pButton icon="pi pi-minus"
                          (click)="el.value=getDecreaseValue(el, elIndex, prevCallback, index, elaborateValidation(el.confReservationElementValidationList, 'MIN', 'NUMBER'))"
                          class="p-button-danger"></button>
                  <input type="text" inputmode="none" readonly placeholder="{{el.label}}" [ngModel]="el.value"/>
                  <button type="button" pButton icon="pi pi-plus" class="p-button-success"
                          (click)="el.value=getIncreaseValue(el, elIndex, index, elaborateValidation(el.confReservationElementValidationList, 'MAX', 'NUMBER'))"
                  ></button>
                </p-inputGroup>
              </div>

            </div>
            <div *ngSwitchCase="4">
              <div class="flex-auto">
                <textarea rows="5" cols="30" [(ngModel)]="el.value" pInputTextarea></textarea>
              </div>
            </div>

          </ng-container>
        </div>
        <div style="padding-right: 10px;padding-top: 10px">
          <p-steps [model]="getSteps(el.info)" [(activeIndex)]="testIndex[elIndex]" [readonly]="true" *ngIf="el.confReservationInputType.id == 5"></p-steps>
          <div *ngIf="testIndex[elIndex]!=-1">
            <p style="width: 100%;text-align: center">{{ el.label }} {{ testIndex[elIndex] + 1 }}</p>
            <div class="card flex flex-column justify-content-center">
              <ng-container *ngFor="let info of el.detailsInfoList">
                <ng-container [ngSwitch]="info.confReservationInputType.id">
                  <div *ngSwitchCase="1">
                    <div class="p-field p-fluid">
                      <label for="asdwqeq">{{ info.label }}</label>
                      <input pInputText id="asdwqeq" class="p-inputtext-lg p-invalid" aria-describedby="asdwqeq-help"
                             [minlength]="elaborateValidation(info.confReservationElementValidationList, 'MIN', 'TEXT')"
                             [maxlength]="elaborateValidation(info.confReservationElementValidationList, 'MAX', 'TEXT')"
                             type="text" [(ngModel)]="el.info[testIndex[elIndex]][info.id]"/>
                    </div>
                  </div>
                  <div *ngSwitchCase="3">
                    <div class="flex flex-column gap-2">
                      label:{{ info.label }}<input type="text" id="start" pInputText class="p-inputtext-lg"
                                                   [(ngModel)]="el.info[testIndex[elIndex]][info.id]"/>
                    </div>
                  </div>
                  <div *ngSwitchCase="6">
                    <div class="flex flex-column gap-2">
                      <label for="username">{{ info.label }}</label>
                      <p-calendar [showIcon]="true" [(ngModel)]="el.info[testIndex[elIndex]][info.id]"  dateFormat="dd/mm/yy"></p-calendar>
                    </div>
                  </div>
                  <div *ngSwitchCase="7">
                    <div class="flex flex-column gap-2">
                      <label for="sesso">{{ info.label }}</label>
                      <p-selectButton [options]="sessi" [(ngModel)]="el.info[testIndex[elIndex]][info.id]"
                                      optionLabel="label"
                                      optionValue="value"></p-selectButton>
                    </div>
                  </div>
                  <div *ngSwitchCase="8">
                    <p-panel [toggleable]="true" [collapsed]="true"
                             header="Clicca qui per personalizzare i {{info.label}}">
                      <div class="flex align-items-center" *ngFor="let item of info.confReservationElementOptions">
                        <p-checkbox [label]="getCheckboxLabel(item)" [name]="item.label" [binary]="true"
                                    [(ngModel)]="el.info[testIndex[elIndex]][info.id+'_'+item.id]"></p-checkbox>
                      </div>
                    </p-panel>


                  </div>

                </ng-container>
              </ng-container>
            </div>
          </div>
          <div>
            <p-buttonGroup [hidden]="true">
              <p-button label="Indietro" icon="pi pi-angle-left"
                        (click)="prevIndex(getSteps(el.info),elIndex, prevCallback, index)"
                        id="prevButton_{{elIndex}}"></p-button>
              <p-button label="Avanti" icon="pi pi-angle-right" iconPos="right"
                        (click)="nextIndex(getSteps(el.info),elIndex, nextCallback, index)"
                        id="nextButton_{{elIndex}}"></p-button>
            </p-buttonGroup>
          </div>
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
  <div class="bottom-info grid">
    <div class="p-3 col-6">
      <p-button label="Indietro" icon="pi pi-angle-left" (click)="prevButton()"
                [hidden]="activeStep==0&&(testIndex[activeStep]==-1 || testIndex[activeStep]==0)"></p-button>
    </div>
    <div class="p-3 col-6" style="text-align: right">
      <p-button *ngIf="conf.confReservationElements!=undefined"
                label="{{activeStep+1==this.conf.confReservationElements.length?'Riepilogo':'Avanti'}}"
                icon="pi pi-angle-right" iconPos="right" (click)="nextButton()"></p-button>
    </div>
  </div>

</div>
